import { AnchorButton } from '@askable/ui/core/button';
import { Eye } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getUnmodPreviewUrl } from 'utils/url-utils';

import type { ComponentPropsWithoutRef } from 'react';

interface PreviewLinkProps extends ComponentPropsWithoutRef<'a'> {
  asChild?: boolean;
}

export const PreviewLinkButton = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <AnchorButton
      href={getUnmodPreviewUrl({ studyId: study._id })}
      rel="noopener noreferrer"
      target="_blank"
      variant="outline"
    >
      {t('sections.studies.preview')}
    </AnchorButton>
  );
};

export const PreviewLink = ({ ...props }: PreviewLinkProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <a href={getUnmodPreviewUrl({ studyId: study._id })} target="_blank" rel="noreferrer" {...props}>
      <Eye className="h-4 w-4" /> {t('sections.studies.preview')}
    </a>
  );
};
