import { GripVertical } from 'lucide-react';

import { cn } from '../../lib/utils';
import { CameraRecordingPreview } from '../task-card/camera-recording-preview';

import type { ComponentProps } from 'react';

type Props = ComponentProps<typeof CameraRecordingPreview> & {
  title: string;
  dragHandleProps: Record<string, unknown>;
  isDisabled?: boolean;
  isDraggable?: boolean;
};

export const TaskCardDraggableHeader = ({
  title = 'Task',
  dragHandleProps,
  isDisabled,
  isDraggable,
  cameraPreview,
  recordingStarted,
  recordingEnded,
}: Props) => (
  <div
    className={cn('group flex !cursor-auto touch-none items-center justify-between gap-3 p-3', {
      '!cursor-grab active:!cursor-grabbing': isDraggable && !isDisabled,
    })}
    title={isDraggable && !isDisabled ? 'Drag card' : undefined}
    {...dragHandleProps}
  >
    <div className="flex-1">
      <div className="w-fit rounded-lg bg-background-subtle px-3 py-2 text-xs font-medium leading-none">{title}</div>
    </div>
    {cameraPreview && (
      <CameraRecordingPreview
        cameraPreview={cameraPreview}
        recordingStarted={recordingStarted}
        recordingEnded={recordingEnded}
      />
    )}
    {isDraggable && (
      <GripVertical className="h-4 w-4 text-muted-foreground group-hover:text-foreground group-active:text-foreground" />
    )}
  </div>
);
